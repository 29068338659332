import { Container } from "react-bootstrap";
import SingleBanner from "../../../ui/banners/SingleBanner";
import logo from "../../../../images/logo.svg";

const ImagesBanner = (props) => {
  function encodeImagePath(imagePath) {
    return imagePath?.split(" ").join("%20");
  }
  return (
    <Container fluid className="!px-0 !mx-0">
      <div className="!grid !grid-cols-1 !w-full">
        {props.data?.map((slide, i) => {
          const catName = props.categories?.find(
            (cat) => cat.id === slide.r_category_id
          );
          return (
            <SingleBanner
              key={slide.id}
              id={slide.id}
              index={i}
              image={encodeImagePath(slide.main_image)}
              link={slide.slug}
              btnText={slide.button_text}
              description={catName?.name}
              bannerText={slide.banner_text}
              bannerLogo={logo}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default ImagesBanner;
