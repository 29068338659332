import { useState, useEffect } from "react";
import logo from "../../../images/logo.svg";
import darkLogo from "../../../images/dark-logo.svg";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const isSolidNav =
    location.pathname.split("/")[1] === "work" ||
    location.pathname.split("/")[1] === "contact-us" ||
    location.pathname.split("/")[1] === "projects" ||
    location.pathname.split("/")[1] === "not-found" ||
    location.pathname.split("/")[1] === "products";

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeightThreshold = 200;

      if (window.scrollY >= scrollHeightThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${
        isSolidNav
          ? "!bg-white"
          : isScrolled
          ? "glassBackground"
          : "!bg-transparent"
      } !fixed !top-0 !left-0 !right-0 !w-full !flex !justify-between !items-center !px-5 !py-5 lg:!px-20 lg:!py-3 !z-50`}
    >
      <div className="!w-[60px] !h-[60px] md:!w-[100px] md:!h-[85px]">
        <NavLink to="/">
          <img
            src={isSolidNav ? darkLogo : logo}
            alt="Hamze"
            className="w-full h-full"
          />
        </NavLink>
      </div>
      <div className="!w-[63%] md:!w-[35%] lg:!w-[25%] !flex !items-center !justify-between !font-semibold !text-[12px] md:!text-[15px]">
        <NavLink
          to="/products"
          className={`${isSolidNav ? "!text-black" : "!text-white"} uppercase`}
        >
          products
        </NavLink>
        <NavLink
          to="/work"
          className={`${isSolidNav ? "!text-black" : "!text-white"} uppercase`}
        >
          work
        </NavLink>
        {/* <NavLink
          to="/about-us"
          className={`${isSolidNav ? "!text-black" : "!text-white "} uppercase`}
        >
          about us
        </NavLink> */}
        <NavLink
          to="/contact-us"
          className={`${isSolidNav ? "!text-black" : "!text-white "} uppercase`}
        >
          contact
        </NavLink>
        {/* <a
          href={`https://wa.me/+96171404114`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i
            className={`${
              isSolidNav ? "!text-black" : "!text-white"
            } fa-brands fa-whatsapp !text-[25px] md:!text-[35px]`}
          />
        </a> */}
      </div>
    </div>
  );
};

export default Navbar;
