import image1 from "../../images/home-slides/image1.png";
import logo from "../../images/logo.svg";

const sliderData = [
  {
    id: 0,
    description:
      "Electrical Engineering Solutions Where Science, Technology and Innovation Converge",
    buttonText: "Read More",
    imageUrl: image1,
    arrow: true,
    bannerText: "A Name You Can Trust",
    bannerImage: logo,
  },
];

// export const products = [
//   {
//     id: 1,
//     image: p2,
//     feedback: 5,
//     name: "solar panels",
//   },
//   {
//     id: 2,
//     image: p5,
//     feedback: 5,
//     name: "Inverters",
//   },
//   {
//     id: 3,
//     image: p1,
//     feedback: 5,
//     name: "Lithium Battries",
//   },
//   {
//     id: 4,
//     image: p4,
//     feedback: 5,
//     name: "Acid Battries",
//   },
//   {
//     id: 5,
//     image: p3,
//     feedback: 5,
//     name: "Gel Battries",
//   },
// ];

export default sliderData;
