const ProductCard = (props) => {
  return (
    <div
      className="!m-auto md:!m-0 !h-[345px] !bg-white !felx !flex-col !items-center !justify-center
      !gap-[30px] !rounded-[30px] !drop-shadow-md !pt-[10px] !px-[10px] !pb-[15px]"
      style={{ padding: "10px, 10px, 15px, 10px" }}
    >
      <div className="!w-full !h-[230px] !rounded-[20px] !border-[1px] !border-gray-300">
        <img
          src={props.image}
          alt={props.name}
          className="!w-full !h-full !object-contain"
        />
      </div>
      <div className="!flex !flex-col !items-center !justify-between !mt-3">
        <div className="!w-[80%] !flex !items-center !justify-between">
          <i className="fa-solid fa-star" style={{ color: "#F39300" }}></i>
          <i className="fa-solid fa-star" style={{ color: "#F39300" }}></i>
          <i className="fa-solid fa-star" style={{ color: "#F39300" }}></i>
          <i className="fa-solid fa-star" style={{ color: "#F39300" }}></i>
          <i className="fa-solid fa-star" style={{ color: "#F39300" }}></i>
        </div>
        <div className="!mt-3">
          <button className="!capitalize !min-w-[90%] !py-[10px] !px-[30px] !bg-[#00A18E] !text-white !rounded-[10px] !font-semibold !text-[16px]">
            {props.name}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
