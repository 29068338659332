import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
//
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import BackdropSwiper from "../../ui/swiper/BackdropSwiper";
//
const ProjectDetail = (props) => {
  const { project } = useParams();
  // const data = projects.find((p) => p.name === project);
  const [data, setData] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [relatedProjects, setRelatedProjects] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const getData = (title) => {
    const content = props.projects?.find((s) => s.slug === title);
    if (content) {
      const images = props.images?.filter((i) => i.row_id === content.id);
      if (images) {
        setAdditionalImages(images);
      }
      setData(content);

      const relatedP = props.projects?.filter(
        (p) => p.r_category_id === content.r_category_id && p.slug !== title
      );
      setRelatedProjects(relatedP);
    }
  };

  useEffect(() => {
    if (project && props.projects) {
      getData(project);
    }
  }, [project, props.projects]);

  useEffect(() => {
    if (openModal) {
      // Disable scrolling when the backdrop is opened
      document.body.style.overflow = "hidden";
    } else {
      // Re-enable scrolling when the backdrop is closed
      document.body.style.overflow = "auto";
    }

    return () => {
      // Clean up the effect by re-enabling scrolling on component unmount
      document.body.style.overflow = "auto";
    };
  }, [openModal]);

  const imagesHandler = (i) => {
    setOpenModal(true);
    setActiveIndex(i);
  };

  function encodeImagePath(imagePath) {
    return imagePath?.split(" ").join("%20");
  }

  return (
    <>
      {openModal && (
        <BackdropSwiper
          images={additionalImages}
          activeIndex={activeIndex}
          backdropClick={() => {
            setOpenModal(false);
          }}
        />
      )}
      <Container
        fluid
        className="!pb-10 !px-0 sm:!px-10 md:!px-20 !pt-32 !bg-[#f5f5f5]"
      >
        {additionalImages.length > 0 && (
          <Row className="!m-auto !w-[90%] !flex !items-center !justify-center">
            <Swiper
              pagination={true}
              modules={[Autoplay, Pagination]}
              loop={true}
              spaceBetween={20}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              className="project-swipper !px-0 !h-[200px] sm:!h-[350px] xl:!h-[500px]"
            >
              {additionalImages?.map((image, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="!rounded-[20px] !w-full !flex !items-center !justify-center"
                  >
                    <img
                      src={image.file_path}
                      className="!cursor-pointer !m-auto !object-cover !w-full !h-full !rounded-[20px]"
                      alt="..."
                      onClick={() => {
                        imagesHandler(index);
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Row>
        )}
        <Row className="!px-5 md:!px-20 !pt-4 md:!pt-8">
          <h1 className="!text-[#202020] !font-bold md:!leading-[30px]">
            {data?.name}
          </h1>
          <p
            className="!font-medium !leading-[30px] md:!leading-[40px]"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></p>
        </Row>
        {relatedProjects.length > 0 && (
          <Row className="!px-5 md:!px-20 !pt-4 md:!pt-8">
            <h1 className="!px-0 !text-[#202020] !font-bold md:!leading-[30px]">
              View More Related Projects
            </h1>
            <div className="!mt-4 !grid !grid-cols-1 md:!grid-cols-3 !place-items-center">
              {relatedProjects.slice(0, 2).map((r) => (
                <Link to={`/projects/${r.slug}`} key={r.id}>
                  <div className="!w-[95%] !h-[230px] md:!w-[400px] !flex !flex-col">
                    <img
                      src={encodeImagePath(r.main_image)}
                      className="!w-full !h-full"
                      alt={r.id}
                    />
                    <p className="!mt-1 !text-center !text-[#202020] !leading-5 !text-[20px] !font-semibold">
                      {r.name}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ProjectDetail;
