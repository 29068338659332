import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
//
import Navbar from "./components/layout/navbar/Navbar";
import ScrollTop from "./components/scroll/ScrollTop";
import Home from "./components/pages/Home";
import Work from "./components/pages/Work";
import Contact from "./components/pages/Contact";
import Footer from "./components/layout/footer/Footer";
import HomeContact from "./components/layout/home/contact/Contact";
import Category from "./components/pages/Category";
import Project from "./components/pages/Project";
import About from "./components/pages/About";
//
import { useQuery } from "react-query";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PropagateLoader } from "react-spinners";
import { Fragment, useState } from "react";
import Products from "./components/pages/Products";
import NotFound from "./components/pages/NotFound";
//

function App() {
  const location = useLocation();
  const isHomeScreen =
    location.pathname === "/" || location.pathname.split("/")[1] === "home";

  // if (isHomeScreen) {
  //   document.body.style.overflowY = "hidden";
  // } else {
  //   document.body.style.overflowY = "auto";
  // }

  const url = "https://cms.ipower-leb.com/main";
  // const url = "http://hamze.local/main";

  const getHomeSlides = async () => {
    const response = await axios.get(`${url}/get-data`);
    return response.data;
  };

  const [showLoader, setShowLoader] = useState(true);
  const { isLoading, data } = useQuery({
    queryKey: ["data"],
    queryFn: getHomeSlides,
    staleTime: 60000,
  });

  useEffect(() => {
    if (!isLoading) {
      if (isHomeScreen) {
        setTimeout(() => {
          setShowLoader(false);
        }, 3000);
      } else {
        setShowLoader(false);
      }
    }
  }, [isLoading]);

  return (
    <div className="App">
      {showLoader && (
        <div className="!bg-black !h-screen !flex !items-center !justify-center">
          <PropagateLoader color="#ffffff" />
        </div>
      )}
      {!showLoader && (
        <Fragment>
          <header>
            <Navbar />
          </header>
          <main>
            <ToastContainer
              icon={true}
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <ScrollTop />
            <Routes>
              <Route
                path="/"
                element={
                  <Home data={data?.slides} categories={data?.categories} />
                }
              />
              <Route
                path="/home"
                element={
                  <Home data={data?.slides} categories={data?.categories} />
                }
              />
              <Route
                path="/products"
                element={<Products data={data?.products} />}
              ></Route>
              <Route
                path="/work"
                element={
                  <Work
                    categories={data?.categories}
                    projects={data?.projects}
                  />
                }
              />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/about-us" element={<About data={data?.about} />} />
              <Route
                path="/service/:id/:category"
                element={
                  <Category data={data?.slides} categories={data?.categories} />
                }
              />
              <Route
                path="/projects/:project"
                element={
                  <Project
                    projects={data?.projects}
                    projects_additional_images={
                      data?.projects_additional_images
                    }
                  />
                }
              />
              <Route path="*" element={<Navigate to="/not-found" replace />} />
              <Route path="/not-found" element={<NotFound />} />
            </Routes>
          </main>
          <footer>
            <HomeContact />
            <Footer />
          </footer>
        </Fragment>
      )}
    </div>
  );
}

export default App;
