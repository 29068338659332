const LogoBanner = (props) => {
  return (
    <div className="!px-3 md:!px-44 !z-10 logoBannerBg !w-full !h-[5vh] md:!h-[10vh] !flex !items-center !justify-evenly">
      <img src={props.logo} className="!object-contain !h-[30px] !w-[30px] md:!h-[60px] md:!w-[70px]" alt="iPower" />
      <p className="!capitalize !text-center !mb-0 !text-[10px] sm:!text-[35px] !font-bold !text-[#c2c2c2]">{props.title}</p>
      <img src={props.logo} className="!object-contain !h-[30px] !w-[30px] md:!h-[60px] md:!w-[70px]" alt="iPower" />
    </div>
  );
};

export default LogoBanner;
