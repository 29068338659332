import { Container, Col, Row } from "react-bootstrap";

const AboutUsForm = (props) => {
  function encodeImagePath(imagePath) {
    return imagePath?.split(" ").join("%20");
  }
  return (
    <Container fluid className="!bg-[#f5f5f5] !mx-0 !px-0">
      <Col>
        <Row className="!h-[45vh] md:!h-[60vh] !w-full !px-0 !mx-0">
          <div
            className="!w-full !h-full !relative"
            style={{
              backgroundImage: `url(${encodeImagePath(props.data?.[0]?.main_image)})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="black-overlay"></div>
            <div className="!z-10 !absolute left-1/2 top-1/2 !transform !-translate-x-1/2 !-translate-y-1/2">
              <p className="!text-center text-white font-semibold leading-[40px] sm:leading-[70px] text-[25px] sm:text-[40px] md:text-[60px]">
                {props.data?.[0]?.title}.
              </p>
            </div>
          </div>
        </Row>
        <Row className="!px-5 md:!px-20 !py-10">
          <p
            dangerouslySetInnerHTML={{ __html: props.data?.[0]?.description }}
          ></p>
        </Row>
      </Col>
    </Container>
  );
};

export default AboutUsForm;
