import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ProductCard from "../../ui/cards/ProductCard";

const ProductsForm = (props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredProducts = props.data?.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  function encodeImagePath(imagePath) {
    return imagePath?.split(" ").join("%20");
  }

  return (
    <Container
      fluid
      className="!px-5 sm:!px-10 md:!px-20 !pt-32 !pb-10 !bg-[#f5f5f5] !flex !items-center !flex-col"
    >
      <Row className="!mt-4 !w-full">
        <div className="!flex !flex-col md:!flex-row !items-center !justify-between">
          <div>
            <h1 className="!text-black md:!text-[40px] !font-bold">
              Our Top Products
            </h1>
          </div>
          <div className="lg:!w-[300px] !h-[40px] !relative !rounded-[100px] !border-[1px] !border-[#202020]">
            <input
              type="search"
              className="!px-3 focus:!outline-none !border-none !border-transparent !border-0 !bg-transparent !min-h-full !w-[90%]"
              placeholder="Search"
              onChange={(e) => setSearchQuery(e.target.value)}
            ></input>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        </div>
      </Row>
      <Row className="!mt-4 !w-full !grid !place-items-center">
        <div className="!text-center !w-full !flex !items-center !justify-between !flex-wrap !gap-5">
          {filteredProducts.length > 0 &&
            filteredProducts?.map((p, i) => (
              <ProductCard
                key={i}
                name={p.name}
                image={encodeImagePath(p.main_image)}
              />
            ))}
          {searchQuery?.length > 0 && filteredProducts.length <= 0 && (
            <h1 className="!my-10 !text-center !w-full">
              No Products Match Your Search.
            </h1>
          )}
          {searchQuery?.length === 0 && filteredProducts.length === 0 && (
            <h1 className="!my-10 !text-center !w-full">
              No Products For Now, Please Try Again Later.
            </h1>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default ProductsForm;
