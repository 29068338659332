import { Container } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container className="!py-32">
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-gray-800">404</h1>
          <p className="text-xl text-gray-600 mb-4">Page not found</p>
          <p className="text-gray-500">
            The page you are looking for might be under construction or does not
            exist.
          </p>
          {/* You can add a link back to the home page or any other relevant page */}
          {/* <a href="/" className="text-blue-500 hover:underline">Go back to the home page</a> */}
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
