import { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import bg from "../../../images/contact-us/contactus-bg.png";
import { useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

const ContactForm = () => {
  //
  const url = "https://cms.ipower-leb.com/main";
  // const url = "http://hamze.local/main";

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      case "phone":
        return value.length < 5
          ? "Phone must be at least 5 characters long"
          : "";
      case "message":
        return value.length < 10
          ? "Message must be at least 10 characters long"
          : "";
      default:
        return "";
    }
  };

  const createMessage = async ({ name, email, phone, message }) => {
    var formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("message", message);
    await axios
      .post(`${url}/set-message`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          toast.success(res.data.message);
          formValues.name = "";
          formValues.email = "";
          formValues.phone = "";
          formValues.message = "";
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: createMessage,
  });

  const formSubmitHandler = (e) => {
    e.preventDefault();

    const errors = {
      name: validateInput("name", formValues.name),
      email: validateInput("email", formValues.email),
      phone: validateInput("phone", formValues.phone),
      message: validateInput("message", formValues.message),
    };
    setFormErrors(errors);

    const isValid = Object.values(errors).every((error) => error === "");
    if (isValid) {
      // if (recaptchaChecked) {
      mutate({
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        message: formValues.message,
      });
      // } else {
      //   toast.error(
      //     <CustomToast message="Please complete the reCAPTCHA verification before submitting the form." />
      //   );
      // }
    } else {
      toast.error("Invalid Form.");
    }
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateInput(name, value),
    }));
  };
  //
  return (
    <Container
      fluid
      className="!px-0 sm:!px-10 md:!px-20 !pt-32 md:!pt-40 !pb-10 !bg-[#f5f5f5]"
    >
      <Container>
        <div
          className="!bg-[85%] !w-full !border-none !drop-shadow-lg !rounded-[40px] !px-10 !py-10"
          style={{
            background: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Col className="md:!w-[60%] lg:!w-[40%]">
            <Row>
              <h1 className="!font-extrabold !text-[#F7B844] !text-center md:!text-[40px]">
                Get In Touch
              </h1>
            </Row>
            <Row className="!mt-6">
              <Form onSubmit={formSubmitHandler}>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  size="lg"
                  name="name"
                  value={formValues.name}
                  onChange={inputChangeHandler}
                  isInvalid={formErrors.name !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.name}
                </Form.Control.Feedback>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  className="!mt-4"
                  size="lg"
                  name="email"
                  value={formValues.email}
                  onChange={inputChangeHandler}
                  isInvalid={formErrors.email !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
                <Form.Control
                  type="text"
                  placeholder="Phone number"
                  className="!mt-4"
                  size="lg"
                  name="phone"
                  value={formValues.phone}
                  onChange={inputChangeHandler}
                  isInvalid={formErrors.phone !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.phone}
                </Form.Control.Feedback>
                <Form.Control
                  as="textarea"
                  rows="10"
                  aria-label="With textarea"
                  placeholder="Tell us about your project"
                  className="!mb-2 !mt-5"
                  name="message"
                  value={formValues.message}
                  onChange={inputChangeHandler}
                  isInvalid={formErrors.message !== ""}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.message}
                </Form.Control.Feedback>
                <Row className="!mt-5 !w-full">
                  <Button
                    type="submit"
                    className="!text-black !px-14 !py-2 !bg-[#F7B844] !border-[#F7B844] !font-bold !m-auto !w-auto"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="!flex !items-center !justify-between">
                        Loading
                        <Spinner className="!ml-2" size="sm" animation="grow" />
                      </span>
                    ) : (
                      "Send"
                    )}
                  </Button>
                </Row>
              </Form>
            </Row>
          </Col>
        </div>
      </Container>
    </Container>
  );
};

export default ContactForm;
